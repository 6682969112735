.s-result-found {
  display: none;
  padding: 30px 0;
  background-color: $color-5;

  &.active {
    display: block;
  }

  &__image {
    width: 146px;
  }

  &__title {
    margin-bottom: 16px;
    width: 243px;
    font-weight: bold;
    font-size: 40px;
    line-height: 49px;
    color: #0C261C;
  }

  .back-to-top {
    width: 100%;
    margin-top: 32px;

    &:before {
      content: '';
      background-image: url('/images/icons/arrow-up.svg');
      height: 22px;
      width: 17px;
      margin-right: 16px;
    }

    &:hover {
      &:before {
        background-image: url('/images/icons/arrow-up-white.svg');
      }
    }
  }

  @include media(">large") {
    &__image {
      margin-bottom: 20px;
    }

    &__title {
      margin-bottom: 54px;
      width: auto;
    }

    .back-to-top {
      margin-top: 60px;
    }
  }
}