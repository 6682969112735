@mixin arrow {
  border: solid $color-9;
  border-width: 0 1px 1px 0;
  display: inline-block;
  padding: 6px;
}

@mixin arrow-down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}