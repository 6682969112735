.select-box {
  display: flex;
  flex-direction: column;
}

.select-box .options-container {
  color: $color-9;
  border: 1px solid $color-9;
  border-top: 0;
  max-height: 0;
  width: 100%;
  opacity: 0;
  transition: all 0.4s;
  border-radius: 0 0 20px 20px;
  overflow: hidden;

  order: 1;
}

.selected {
  border: 1px solid $color-9;
  border-radius: 20px;

  position: relative;
  order: 0;
  display: flex;
  align-items: center;
  height: 40px;
}

.selected::after {
  content: "";
  @include arrow;
  @include arrow-down;

  position: absolute;
  top: 10px;
  right: 13px;

  transition: all 0.4s;
}

.select-box .options-container.active {
  max-height: 240px;
  opacity: 1;
}

.select-box .options-container.active {

  & + .selected {
    border-radius: 20px 20px 0 0;

    &::after {
      transform: rotate(225deg);
      top: 20px;
    }
  }
} 

.select-box .options-container::-webkit-scrollbar {
  width: 8px;
  background: #0d141f;
  border-radius: 0 8px 8px 0;
}

.select-box .options-container::-webkit-scrollbar-thumb {
  background: #525861;
  border-radius: 0 8px 8px 0;
}

.select-box .option,
.selected {
  padding: 6px 18px;
  cursor: pointer;
  
  color: $color-9;
  font-weight: 300;
  font-size: 14px;
  line-height: 27px;
}

.select-box .option:hover {
  background: $color-3;
  color: $white;
}

.select-box label {
  cursor: pointer;
}

.select-box .option .radio {
  display: none;
}

@include media(">large") {
  .select-box {
    width: 300px;
  }

  .selected {

  }
}