// -----------------------------------------------------------------------------
// This file contains all application-wide Sass variables.
// -----------------------------------------------------------------------------





/// Regular font family
/// @type List
$font-1: "Montserrat", sans-serif !default;

/// Code (monospace) font family
/// @type List
$font-2: 'Roboto', sans-serif !default;


$black: #000;
$white: #FFF;


/// Copy text color
/// @type Color
$text-color: rgb(34, 34, 34) !default;

/// Main brand color
/// @type Color
$brand-color: rgb(229, 0, 80) !default;

/// Light grey
/// @type Color
$light-grey: rgb(237, 237, 237) !default;

/// Medium grey
/// @type Color
$mid-grey: rgb(153, 153, 153) !default;

/// Dark grey
/// @type Color
$dark-grey: rgb(68, 68, 68) !default;

$color-1: #15573F;
$color-2: #FD9872;
$color-3: #75BC97;
$color-4: #848484;
$color-5: #F2F2F2;
$color-6: #A9A9A9;
$color-7: #257556;
$color-8: #6E6E6E;
$color-9: #A8A8A8;




/// Container's maximum width
/// @type Length
$max-width: 1445px !default;





/// Breakpoints map
/// @prop {String} keys - Keys are identifiers mapped to a given length
/// @prop {Map} values - Values are actual breakpoints expressed in pixels
$breakpoints: (
  'small': 320px,
  'medium': 768px,
  'large': 1024px,
) !default;






/// Relative or absolute URL where all assets are served from
/// @type String
/// @example scss - When using a CDN
///   $base-url: 'http://cdn.example.com/assets/';
$base-url: '/assets/' !default;