.s-plant-selection {
  padding: 20px 0 40px;

  img {
    height: 75px;
    margin-bottom: 15px;
  }

  &__instructions {
    margin-bottom: 24px;
    width: 220px;
    color: $color-4;
    font-size: 14px;
    line-height: 22px;
  }

  @include media("<medium") {
    &__step {
      &:not(:last-of-type) {
        margin-bottom: 50px;
      }
    }
  }

  @include media(">large") {
    padding: 50px 0;

    .container {
      display: flex;
      justify-content: space-between;
    }

    &__step {
      &:not(:last-of-type) {
        margin-right: 100px;
      }
    }
  }
}