.is-staff-favorite {
  @include media(">large") {

    &.plant__item {
      padding-bottom: 26px;
    }
    
    .plant {
      
      &__details {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      
      &__right {
        flex-direction: column;
        align-items: flex-end;
      }
      
      &__name {
        font-weight: bold;
        font-size: 35px;
        line-height: 37px;
      }
      
      &__price {
        margin-bottom: 15px;
        font-size: 24px;
      }
    }
    
    .icon {
      height: 26px;
      min-width: 35px;
    }
  }
}